import React, {lazy} from 'react';
import queryString from 'query-string'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import {history} from './history';

import * as dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import utc from 'dayjs/plugin/utc'
import duration from 'dayjs/plugin/duration'
import relativetime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone' // dependent on utc plugin
import customParseFormat from 'dayjs/plugin/customParseFormat'

import { nanoid } from 'nanoid'

dayjs.extend(advancedFormat)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(duration)
dayjs.extend(relativetime)
dayjs.extend(customParseFormat)

export const isProduction = (process.env.NODE_ENV === 'production')

export const HEADER_HEIGHT_PIXELS = 70
export const FOOTER_HEIGHT_PIXELS = 70
export const MAX_TEST_RESPONSE_BUFFER_SIZE = 5
export const MS_PER_HOUR = 1000 * 60 * 60
export const FREE_TRIAL_LENGTH_DAYS = 3

export const HEALTH_EXPERT_COUNT = 1000

export const MaxResponseLength = 1000

const DefaultFbPixelEventIdLength = 15

let SharedMixpanelInstance;

const getMixpanel = async () => {
  while (!SharedMixpanelInstance) {
    await delay(1000)
  }
  return SharedMixpanelInstance
}
// Google Analytics Utils
export const initializeGA = async (disableCookies=false) => {
  try {
    if (isProduction && !SharedMixpanelInstance) {
      // ReactGA.initialize('UA-240315789-1', gaConfig);
      const MixpanelToken = "1187afc3c6a3b394ad3824b9eab9f785"
      const mixpanelInstance = (await import("mixpanel-browser")).default
      mixpanelInstance.init(MixpanelToken, {debug: (!(isProduction)), disable_persistence: disableCookies})
      SharedMixpanelInstance = mixpanelInstance
    }
  } catch (err) {
    console.error(err)
  }
}

export const initializeDashboardGA = async (disableCookies=false) => {
  try {
    if ((isProduction || true) && !SharedMixpanelInstance) {
      // ReactGA.initialize('UA-240315789-1', gaConfig);
      const MixpanelToken = "c61a7cc3ea29dd051d312a4227b59a63"
      const mixpanelInstance = (await import("mixpanel-browser")).default
      mixpanelInstance.init(MixpanelToken, {debug: (!(isProduction)), disable_persistence: disableCookies})
      SharedMixpanelInstance = mixpanelInstance
    }
  } catch (err) {
    console.error(err)
  }
}

export const pageViewGA = () => {
  try {
    if (isProduction) {
      // ReactGA.pageview('/');
    }
  } catch (err) {
    console.error(err)
  }
}

export const recordEventGA = async (eventType, eventName, properties={}) => {
  try {
    if (isProduction) {
      const days_since_signup = Math.floor(getDaysSinceTimestamp(UserCreatedAtUnix))
      const weeks_since_signup = Math.floor(getWeeksSinceTimestamp(UserCreatedAtUnix))
      const months_since_signup = Math.floor(getMonthsSinceTimestamp(UserCreatedAtUnix))
      const is_premium_user = IsPremiumUser
      const days_since_premium = IsPremiumUser ? Math.floor(getDaysSinceTimestamp(UserStartedPremiumAtUnix)) : -1
      const weeks_since_premium = IsPremiumUser ? Math.floor(getWeeksSinceTimestamp(UserStartedPremiumAtUnix)) : -1
      const months_since_premium = IsPremiumUser ? Math.floor(getMonthsSinceTimestamp(UserStartedPremiumAtUnix)) : -1
      const mixpanel = await getMixpanel()
      mixpanel.track(eventName, {event_type: eventType, ...properties, days_since_signup, weeks_since_signup, months_since_signup, is_premium_user, days_since_premium, weeks_since_premium, months_since_premium})
      // ReactGA.event({
      //   category: eventType,
      //   action: eventName,
      //   label: "Analytics Event",
      //   value: 1
      // });
    }
  } catch (err) {
    console.error(err)
  }
}

export const recordFunnelEventGA = (eventName) => {
  try {
    if (isProduction) {
      recordEventGA("New User Funnel", eventName)
    }
  } catch (err) {
    console.error(err)
  }
}

export const recordPurchaseFunnelEventGA = (eventName, productId) => {
  try {
    recordFunnelEventGA(eventName)
    if (!!(productId)) {
      recordFunnelEventGA(`${eventName}: ${productId}`)
    }
  } catch (err) {
    console.error(err)
  }
}

export const recordStoryReadEvent = (storyKey) => {
  return recordEventGA("Story Read", storyKey)
}

export const recordLinkClickGA = (eventName) => {
  try {
    return recordEventGA("Link Click", eventName);
  } catch (err) {
    console.error(err)
  }
}

export const recordLinkClickAndRedirect = async (eventName, redirectUrl, sameTab) => {
  if (isProduction) {
    try {
      const mixpanel = await getMixpanel()
      mixpanel.track(eventName, {}, () => {
        if (!!(sameTab)) {
          window.location = redirectUrl
        } else {
          window.open(redirectUrl, "_blank", "noopener nofollow")
        }
      })
    } catch (err) {
      if (!!(sameTab)) {
        window.location = redirectUrl
      } else {
        window.open(redirectUrl, "_blank", "noopener nofollow")
      }
      console.error(err)
    }
  } else {
    if (!!(sameTab)) {
      window.location = redirectUrl
    } else {
      window.open(redirectUrl, "_blank", "noopener nofollow")
    }
  }
}

export const recordSignupFunnelEventGA = (eventName) => {
  try {
    return recordFunnelEventGA(eventName);
  } catch (err) {
    console.error(err)
  }
}

export const recordFunnelEventResultGA = (eventName, properties) => {
  try {
    if (isProduction) {
      recordEventGA("Event Result", eventName, properties)
    }
  } catch (err) {
    console.error(err)
  }
}

export const recordSessionEventGA = (sessionType) => {
  try {
    return recordEventGA("Session", sessionType)
  } catch (err) {
    console.error(err)
  }
}

let ENABLE_PIXEL = false;
let ReactPixel;
let TikTokPixel;

export const initializePixelIfAvailable = async (initTikTok=false) => {
  try {
    if (isProduction) {
      const {name} = getTimeZoneOffset()
      ENABLE_PIXEL = !name.startsWith("Europe")
      // GDPR-Compliant
      if (ENABLE_PIXEL) {
        ReactPixel = require('react-facebook-pixel').default
        ReactPixel.init("2022945354569015", {}, {autoConfig: true, debug: false});
        ReactPixel.pageView(); // For tracking page view

        if (initTikTok) {
          TikTokPixel = require('tiktok-pixel').default
          TikTokPixel.init('CJMFOEBC77UEOD73J3EG', {}, {debug: false});
          TikTokPixel.pageView()
        }
      }
    }
  } catch (err) {
    console.error(`Error Loading Pixel: ${err}`)
  }
}

// TODO: Record Events: Lead, AddToCart, AddPaymentInfo, InitiateCheckout, Purchase
export const recordPixelEventIfAvailable = (eventName, productId, selectedProductPriceCents=undefined, uniqueEventId=undefined) => {
  const activeEventId = uniqueEventId || nanoid(DefaultFbPixelEventIdLength)
  const productPricesUsd = {
    "book": 0.99,
    "book_bundle": 35.99,
    "neurofit_certification": 1997.00,
    "nplus_session": 2997.00,
    "course": 97.00,
    "course_bundle": 127.00,
    "masterclass": 47.00,
    "masterclass_bundle": 77.00,
    "neurofit_app_pre_purchase_3_months": 35.99,
    "neurofit_app_pre_purchase_12_months": 124.99,
  }
  const productValueUsd = !!(selectedProductPriceCents) ? (selectedProductPriceCents / 100) : (productPricesUsd[productId] || 1997.00)
  if (isProduction && ENABLE_PIXEL && !!(ReactPixel)) {
    ReactPixel.fbq('track', eventName, {currency: "USD", value: productValueUsd}, {eventID: activeEventId})
    
    if (!!TikTokPixel) {
      const tikTokEventName = (eventName === "Purchase") ? "CompletePayment" : (eventName === "Lead" ? "CompleteRegistration" : eventName)
      TikTokPixel.track(tikTokEventName, {content_type: "product", content_id: productId, quantity: 1, value: productValueUsd, currency: "USD"}, {event_id: activeEventId})
    }
  }
}


export let UserCreatedAtUnix = -1
export let IsPremiumUser = false
export let UserStartedPremiumAtUnix = -1
export let UserUuid = ""
export const SetUserFieldsUnix = (createdAtUnix, isPremiumUser, startedPremiumAtUnix) => {
  UserCreatedAtUnix = createdAtUnix
  IsPremiumUser = isPremiumUser
  UserStartedPremiumAtUnix = startedPremiumAtUnix
}

export const configureAnalyticsUser = async ({uuid="", aliasUuid="", isPremiumUser=false, hasStartedFreeTrial=false, hasConvertedPaidSubscription=false, hasActiveSubscription=false, activeSubscriptionId="", language='en', createdAtUnix=-1, startedPremiumAtUnix=-1, experimentFlags={}}) => {
  try {
    if (isProduction) {
      UserUuid = uuid
      let userProperties = {
        'neurofit_uuid': uuid,
        'has_valid_session': true,
        'is_premium_user': isPremiumUser,
        'has_started_free_trial': hasStartedFreeTrial,
        'has_converted_paid_subscription': hasConvertedPaidSubscription,
        'has_active_subscription': hasActiveSubscription,
        'active_subscription_id': activeSubscriptionId,
        'language': language,
        'created_at_unix': createdAtUnix,
        'user_retention_days': Math.floor(getDaysSinceTimestamp(createdAtUnix)),
        'user_retention_weeks': Math.floor(getWeeksSinceTimestamp(createdAtUnix)),
        'user_retention_months': Math.floor(getMonthsSinceTimestamp(createdAtUnix)),
        ...experimentFlags
      }
      if (isPremiumUser) {
        userProperties.days_since_premium = Math.floor(getDaysSinceTimestamp(startedPremiumAtUnix))
        userProperties.weeks_since_premium = Math.floor(getWeeksSinceTimestamp(startedPremiumAtUnix))
        userProperties.months_since_premium = Math.floor(getMonthsSinceTimestamp(startedPremiumAtUnix))

      }
      const mixpanel = await getMixpanel()
      mixpanel.identify(uuid)
      mixpanel.people.set(userProperties)
      mixpanel.register(userProperties)
      if (!!aliasUuid) {
        mixpanel.alias(aliasUuid, uuid)
      }
    }
  } catch (err) {
    console.error(err)
  }
}

export const identifyCertificationAnalyticsWebUser = async ({uuid}) => {
  try {
    if (isProduction) {
      const mixpanel = await getMixpanel()
      mixpanel.identify(uuid)
    }
  } catch (err) {
    console.error(err)
  }
}

export const configureAnalyticsUserProperties = async ({experimentFlags={}, asaAttribution={}}) => {
  try {
    if (isProduction) {
      let userProperties = {
        ...experimentFlags,
        ...asaAttribution,
      }
      
      const mixpanel = await getMixpanel()
      mixpanel.people.set(userProperties)
      mixpanel.register(userProperties)
    }
  } catch (err) {
    console.error(err)
  }
}

export const setAnalyticsUserProperty = async (eventName, userProperties) => {
  try {
    if (isProduction) {
      if (!!(UserUuid)) {
        const mixpanel = await getMixpanel()
        mixpanel.identify(UserUuid)
        mixpanel.people.set(userProperties);
        recordEventGA("User Properties", eventName, userProperties)
      }
    }
  } catch (err) {
    console.error(err)
  }
}

export const configureAnalyticsSuperProperties = async (superProperties, disableCookies=false) => {
  try {
    if (isProduction) {
      const mixpanel = await getMixpanel()
      mixpanel.register(superProperties, {persistent: disableCookies})
    }
  } catch (err) {
    console.error(err)
  }
}

export const setAdvertisementReferralUserProperty = async (adIdentifier) => {
  const mixpanel = await getMixpanel()
  mixpanel.people.set({adIdentifier});
}

export const resetAnalyticsUser = async () => {
  try {
    if (isProduction) {
      const mixpanel = await getMixpanel()
      mixpanel.reset();
    }
  } catch (err) {
    console.error(err)
  }
}

export const isGDPRSensitive = (() => {
  const timeZoneName = dayjs.tz.guess()
  return timeZoneName.startsWith("Europe")
})()

export const NeuroFitAppleAppStoreLink = "https://apps.apple.com/us/app/neurofit-nervous-system-reset/id1630278170"
export const NeuroFitGooglePlayStoreLink = "https://play.google.com/store/apps/details?id=com.neurofit.app"
export const NeuroFitUniversalAppStoreLink = "https://neurofit.link/install"
export const CertificationThreePaymentCheckoutLink = "https://neurofitapp.mykajabi.com/offers/Fzv4C2Pt/checkout"
export const CertificationDiscountedThreePaymentCheckoutLink = "https://neurofitapp.mykajabi.com/offers/7vVLqkif/checkout"
export const NeuroFitLiveClassSubscriptionCheckoutLink = "https://neurofitapp.mykajabi.com/offers/XZXo2p3a/checkout"
export const NeuroFitCertificationConfirmationCallLink = "https://calendly.com/d/3b9-7r3-wdd/neurofit-certification-confirmation-call"
export const CalendlyCoachOSDemoLink = "https://calendly.com/neurofit-app/coach-os"
export const CalendlyMasterCoachLink = "https://calendly.com/neurofit-app/master-coach-discovery"
export const KajabiCertificationCourseUrl = "https://neurofitapp.mykajabi.com/library"
export const EmbodiedCeoCourseLink = "https://embodiedceo.life"
export const NeuroFitInstagramLink = "https://www.instagram.com/neurofit.app/"
export const NeuroFitLinkedInLink = "https://www.linkedin.com/company/neurofit-app/"
export const AndrewInstagramLink = "https://www.instagram.com/andrewjhogue/"
export const AndrewLinkedInLink = "https://www.linkedin.com/in/andrewjhogue/"
export const LorenInstagramLink = "https://www.instagram.com/lorendhogue/"
export const LorenLinkedInLink = "https://www.linkedin.com/in/lorenhogue/"
export const NeuroFitInstagramDirectMessageLink = "https://ig.me/m/neurofit.app?ref=cert-page"
export const WellAndGoodArticleLink = "https://www.wellandgood.com/nervous-system-exercises-app/"
export const ShapeArticleLink = "https://www.shape.com/post-workout-recovery-innovations-7501533#toc-nervous-system-regulation-apps"
export const Masterclass100KMonthsCheckoutLink = "https://neurofitapp.mykajabi.com/offers/X3VAL2LF/checkout"
export const MasterclassDailySalesCheckoutLink = "https://neurofitapp.mykajabi.com/offers/N36dWPMw/checkout"
export const MasterclassRichAndRegulatedCheckoutLink = "https://neurofitapp.mykajabi.com/offers/bgEgNori/checkout"
export const Masterclass7FigureOfferSuiteCheckoutLink = "https://neurofitapp.mykajabi.com/offers/Zabqs6za/checkout"
export const NeuroFitSkoolCommunityLink = "https://www.skool.com/neurofit-community/about"
export const AppleAppReviewLink = "https://apps.apple.com/us/app/neurofit-mind-body-coach/id1630278170&action=write-review"

export const KajabiMasterCoachPayInFullLink = "https://neurofitapp.mykajabi.com/offers/aFQdHLju/checkout"
export const KajabiMasterCoachPaymentPlanLink = "https://neurofitapp.mykajabi.com/offers/YyqAzxua/checkout"

export const BlogSomaticExercisesWeightLossUrl = "https://neurofit.app/blog/posts/somatic-exercises-to-help-with-weight-loss"
export const BlogSomaticExercisesSleepUrl = "https://neurofit.app/blog/posts/3-somatic-exercises-to-support-a-good-nights-sleep"
export const BlogSomaticExercisesTraumaUrl = "https://neurofit.app/blog/posts/somatic-exercises-to-release-trauma"
export const BlogDorsalVagalRegulationUrl = "https://neurofit.app/blog/posts/dorsal-vagal-shutdown-nervous-system-regulation-neurofit"
export const BlogSomaticExercisesAngerUrl = "https://neurofit.app/blog/posts/3-somatic-exercises-for-releasing-anger-and-frustration"
export const BlogNervousSystemRegulationTimeUrl = "https://neurofit.app/blog/posts/how-long-does-it-take-to-balance-my-nervous-system-neurofit"
export const BlogNervousSystemCoachUrl = "https://neurofit.app/blog/posts/how-to-become-a-nervous-system-coach"
export const BlogSomaticCoachUrl = "https://neurofit.app/blog/posts/how-to-become-a-somatic-coach"
export const LearnOverstimulatedUrl = "https://neurofit.app/learn/en/overstimulated/"
export const LearnSomaticYogaUrl = "https://neurofit.app/learn/en/what-is-somatic-yoga/"
export const LearnFunctionalFreezeUrl = "https://neurofit.app/learn/en/functional-freeze/"
export const LearnCoreSleepUrl = "https://neurofit.app/learn/en/what-is-core-sleep/"
export const LearnBrainMuscleUrl = "https://neurofit.app/learn/en/is-brain-muscle/"

export const ProductionLegalTermsOfServiceUrl = "https://legal.neurofit.app/#terms"
export const ProductionLegalPrivacyPolicyUrl = "https://legal.neurofit.app/#privacy"

// User Agent Utils
export const getUserAgent = () => {
  return navigator.userAgent || navigator.vendor || window.opera;
}

export const isIOSDevice = () => {
  return (/iPad|iPhone|iPod/.test(getUserAgent()) && !window.MSStream)
}

export const isFirefox = (() => {
  return /firefox/i.test(getUserAgent())
})()

export const isIOSSafari = () => {
  if (!isIOSDevice()) {
    return false
  }
  const ua = window.navigator.userAgent;
  return (!!ua.match(/WebKit/i) && !ua.match(/CriOS/i) && !ua.match(/OPiOS/i))
}

export const isAndroidDevice = () => {
  return (/android/i.test(getUserAgent()))
}

export const getIsMetaInAppBrowser = () => {
  return navigator.userAgent.match(/FBAN|FBAV|Instagram/i)
}

export const runningAsApp = () => {
  if (isIOSDevice()) {
    return (window.navigator.standalone === true)
  } else {
    // Android
    return window.matchMedia('(display-mode: standalone)').matches
  }
}

export const isIOSStandalone = () => {
  return isIOSDevice() && (window.navigator.standalone === true)
}

export const baseDynamicLinkDomain = "neurofit.link"
export const baseDomain = "neurofit.app"
export const baseCoachingDomain = "neurofit.coach"
export const baseAppDomain = `native.${baseDomain}`
export const baseDashboardDomain = `dashboard.${baseDomain}`
export const baseAppHost = isProduction ? baseDomain : "localhost:4100"
export const baseNativeAppHost = isProduction ? baseAppDomain : "localhost:4101"
export const baseDashboardAppHost = isProduction ? baseDashboardDomain : "localhost:4102"
export const baseCoachingHost = isProduction ? baseCoachingDomain : "localhost:4100"

export const subdomainHosts = {
  baseAppHost,
  baseAppDomain,
  baseDashboardAppHost,
}

const httpsPrefix =  "https://"
export const httpPrefix = isProduction ? httpsPrefix : "http://"

export const DefaultWebsiteBaseUrl = `https://${baseDomain}`
export const DefaultCdnBaseUrl = `https://cdn.${baseDomain}`
export const CoachProfileCdnBaseUrl = `https://trainer-profiles.${baseDomain}`
export const DefaultProductionNativeBaseUrl = `https://${baseAppDomain}`
export const DefaultQuizResultCdnBaseUrl = `https://quiz-results.${baseDomain}`
export const DefaultCertificationCodesCdnBaseUrl = `https://certification-codes.${baseDomain}`
export const DefaultCourseCodesCdnBaseUrl = `https://course-codes.${baseDomain}`
export const ProductionBaseUrl =  `https://${baseDomain}`
export const ProductionBlogBaseUrl = `https://${baseDomain}/learn`
export const ProductionGlossaryBaseUrl = `https://${baseDomain}/nervous-system-glossary`
export const ProductionAboutBaseUrl = `https://${baseDomain}/about`
export const ProductionAboutLorenBaseUrl = `https://${baseDomain}/author/loren-hogue`
export const ProductionAboutAndrewBaseUrl = `https://${baseDomain}/author/andrew-hogue`
export const ProductionLinksBaseUrl = `https://${baseDomain}/links`
export const ProductionProductDemoBaseUrl = `https://${baseDomain}/product-demo`
export const ProductionCreatorsUrl = `https://${baseDomain}/creators`
export const ProductionCertificationTrainingUrl = `https://${baseDomain}/training`
export const ProductionReferencesUrl = `https://${baseDomain}/references`
export const ProductionCertificationUrl = `https://${baseDomain}/certification`
export const ProductionMasterCoachUrl = `https://${baseDomain}/master-coach`
export const ProductionPlatformUrl = `https://${baseDomain}/platform`
export const ProductionCoachOSUrl = `${httpPrefix}${baseAppHost}/coach-os`
export const ProductionAppWebCheckoutUrl = `https://${baseDomain}/app-checkout`
export const ProductionCourseUrl = `https://${baseDomain}/course`
export const ProductionLiveClassesUrl = `https://${baseDomain}/classes`
export const ProductionCommunityUrl = `https://${baseDomain}/community`
export const ProductionSupportBaseUrl = `https://${baseDomain}/support`
export const ProductionQuizBaseUrl = `https://${baseDomain}/quiz`
export const ProductionLegalUrl = `https://${baseAppDomain}/legal/`
export const ProductionAzureClubRsvpUrl = `https://${baseDomain}/azureclub`
export const ProductionWorkshopsUrl = `https://${baseDomain}/workshops`
export const ProductionBalanceUrl = `https://${baseDomain}/balance`
export const ProductionTrainingRegistrationUrl = `https://${baseDomain}/register`
export const ProductionCertificationRegistrationUrl = `https://${baseDomain}/cert-offer`
export const ProductionTeamsOptInUrl = `https://${baseDomain}/teams`
export const ProductionMasterclassRegistrationUrl = `${httpPrefix}${baseAppHost}/masterclass`
export const ProductionNPlusUrl = `${httpPrefix}${baseAppHost}/performance-coaching`
export const ProductionCertificationVslUrl = `https://${baseDomain}/vsl`
export const NeuroFitWorkplaceBurnoutRecoveryPlanLink = `https://${baseDomain}/assets/pdf/burnout-stress-recovery-plan-organizations-neurofit.pdf`
export const NeuroFitCourseCheckoutPage = `${httpPrefix}${baseAppHost}/course/checkout`
export const NeuroFitCertificationCheckoutPage = `${httpPrefix}${baseAppHost}/certification/checkout`

export const getCoachProfileLink = username => `${httpPrefix}${baseCoachingHost}${isProduction ? "/" : "/coach/"}${username}`
export const getCoachProfileImageUrl = (coachUuid, profileImageVersion) => `${CoachProfileCdnBaseUrl}/profile-images/${coachUuid}.jpeg?v=${profileImageVersion}`
export const getCoachDiscoveryQuizUrl = coachUuid => `${httpPrefix}${baseAppHost}/quiz?cid=${coachUuid}`
export const getAppAffiliateLink = affiliateCode => `${ProductionAppWebCheckoutUrl}?c=${affiliateCode}`
export const getCertificationAffiliateLink = affiliateCode => `${ProductionCertificationUrl}?c=${affiliateCode}` 
export const getLearnArticleHeroImageUrl = articleKey => `${DefaultCdnBaseUrl}/learn/images/native/${articleKey}/16x9.jpg`
export const getLearnArticleThumbnailImageUrl = articleKey => `${DefaultCdnBaseUrl}/learn/images/native/${articleKey}/thumbnail.jpg`
export const trimHttpPrefix = inputString => inputString.replace(/^http[s]?:\/\//i, "")

export const PurchaseConfirmationBaseUrl = `${httpPrefix}${baseAppHost}/purchase_confirmation/`

export const ProductionDashboardSignupUrl = `${httpPrefix}${baseDashboardAppHost}/signup`

export const getPrefilledDashboardSignupUrl = emailAddress => `${ProductionDashboardSignupUrl}?prefilled_email=${encodeURIComponent(emailAddress)}`

export const DEFAULT_QUIZ_SESSION_ID_LENGTH = 36

export const AppName = "NEUROFIT"

export const StripePublishableKey = (isProduction) ? 
"pk_live_51LK7yaJv6nSiqyNcavyFRxoyYm6OCKQlZF0U6UF423ccOiG4NYnbxhGH96LhnSU88TOuZekH0VSdcxR1vRSubP1g00B3ADHJNx" :
"pk_test_51LK7yaJv6nSiqyNc8sPaLLTiRYnb19Qw3i76ZHbjYdnArggu7BRPPxftAYyneU4lqHJt2RuRrEuiMjZYCHJVsc7h003980UO5c"


export const baseAppUrl = httpPrefix + baseAppHost
export const baseNativeAppUrl = httpPrefix + baseNativeAppHost
export const baseLegalUrl = httpsPrefix + "legal." + baseDomain
export const baseDashboardAppUrl = httpPrefix + baseDashboardAppHost
export const baseDynamicLinkUrl = httpsPrefix + baseDynamicLinkDomain

export const subdomainUrls = {
  baseAppUrl,
  baseNativeAppUrl,
  baseDynamicLinkUrl,
  baseDashboardAppUrl,
  baseLegalUrl
}

export const toggleBodyScroll = (enable) => {
    if (enable) {
      document.body.classList.remove("noscroll")
    } else {
      document.body.classList.add("noscroll")
    }
}

export const formatContentDuration = (durationSeconds) => {
  if (durationSeconds < 0) {
    return "0:00"
  }
  const seconds = (durationSeconds % 60)
  return Math.floor(durationSeconds / 60) + ":" + ((seconds < 10) ? "0" : "") + seconds
}

export const hideLoadingScreen = () => {
  let loadingScreenElem = document.getElementById("loading-screen")
  if (!!(loadingScreenElem)) {
    loadingScreenElem.style.display = "none"
    // loadingScreenElem.parentNode.removeChild(loadingScreenElem);
  }
}

export const showLoadingScreen = () => {
  let loadingScreenElem = document.getElementById("loading-screen")
  if (!!(loadingScreenElem)) {
    loadingScreenElem.style.display = "block"
    // loadingScreenElem.parentNode.removeChild(loadingScreenElem);
  }
}

export const loadingScreenIsVisible = () => {
  const loadingScreenElem = document.getElementById("loading-screen")
  return !!(loadingScreenElem) && loadingScreenElem.style.display !== "none"
}

export const reloadApp = async () => {
  showLoadingScreen()
  window.location.reload(true/*forcedReload*/)
}

export const removeHttpsPrefixFromLink = link => !!(link) ? link.replace(/^https?:\/\//,'') : ""

export const setWindowUrlToBase = () => {
  history.push("/");
}

export const getCampaignCodeFromQueryString = () => {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  return params.cc
}

export const getAdvertisementIdFromQueryString = () => {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  return params.a
}

export const getCoachUuidFromQueryString = () => {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  return params.cid
}

export const getCoachUsernameFromQueryString = () => {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  return params.cu
}

export const getPrefilledEmailFromQueryString = () => {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  return params.prefilled_email || ""
}

export const getIsLimitedCourseOfferFromQueryString = () => {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  return (params.lto === "1")
}

export const getIsAndrewNPlusCoachingFromQueryString = () => {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  return (params.npid === "andrew")
}

export const getBrandNameFromQueryString = () => {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  return params.brand
}

export const getFbclidFromQueryString = () => {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  return params.fbclid
}

export const getTTClidFromQueryString = () => {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  return params.ttclid
}

export const getAffiliateIdFromQueryString = () => {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  return params.t || ""
}

export const getLimitedTimeCertPromotionActiveFromQueryString = () => {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  return (params.promo === "1")
}

export const getDeeplinkedTestimonialCategoryFromQueryString = (url) => {
  const inputUrlObject = new URL(url || window.location)
  const params = new Proxy(new URLSearchParams(inputUrlObject.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  return params.tc || ""
}

export const getDeeplinkedCheckoutPlanFromQueryString = (url) => {
  const inputUrlObject = new URL(url || window.location)
  const params = new Proxy(new URLSearchParams(inputUrlObject.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  return params.cp || ""
}

export const getDeeplinkedPageSectionKeyFromQueryString = (url) => {
  const inputUrlObject = new URL(url || window.location)
  const params = new Proxy(new URLSearchParams(inputUrlObject.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  return params.v || ""
}

export const getAffiliateCodeFromQueryString = (url) => {
  const inputUrlObject = new URL(url);
  const params = new Proxy(new URLSearchParams(inputUrlObject.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  return params.c || ""
}

export const getCertOptInNameFromQueryString = (url) => {
  const inputUrlObject = new URL(url || window.location)
  const params = new Proxy(new URLSearchParams(inputUrlObject.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  return (!!(params.n)) ? decodeURIComponent(params.n) : ""
}

export const getCertOptInEmailAddressFromQueryString = (url) => {
  const inputUrlObject = new URL(url || window.location)
  const params = new Proxy(new URLSearchParams(inputUrlObject.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  return (!!(params.em)) ? decodeURIComponent(params.em) : ""
}

export const getCertOptInIsPrefilledFromQueryString = (url) => {
  const inputUrlObject = new URL(url || window.location)
  const params = new Proxy(new URLSearchParams(inputUrlObject.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  return (params.prefilled === "1")
}

export const getPaymentIntentClientSecretFromQueryString = () => {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  return params.payment_intent_client_secret
}

const componentToHex = (c) => {
  var hex = c.toString(16);
  return hex.length === 1 ? "0" + hex : hex;
}

export const rgbToHex = (r, g, b) => {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

export const currentTime = () => dayjs()
export const currentTimeUnix = () => dayjs().unix()
export const currentTimeLocal = () => dayjs().local()
export const time = (s, format) => dayjs(s, format)
export const get7AMLocalTimeWithTimezone = () => dayjs().tz(dayjs.tz.guess()).format('[7:00 am] z')
export const getTimeZoneOffset = () => ({
  name: dayjs.tz.guess(),
  offset: dayjs().tz(dayjs.tz.guess()).format('Z')
})

export const getTimeOfDay = (timestampSeconds) => {
  const hr = (!!(timestampSeconds)) ? dayjs.unix(timestampSeconds).local().hour() : dayjs().hour()
  if (hr < 12) {
    return "morning"
  } else if (hr < 17) {
    return "afternoon"
  } else {
    return "evening"
  }
}

export const getIsOptimalHourOfDayForAppReview = () => {
  // Optimal Hours taken from 6 months of Mixpanel data.
  const validHours = new Set([0, 1, 8, 9, 13, 14, 18, 19, 20, 21])
  const hr = dayjs().hour()

  console.log(hr)
  console.log(validHours.has(hr))
  return validHours.has(hr)
}

export const readableDate = dateString => {
    return dayjs(dateString, "YYYY-MM-DD").format('M/D')
}

export const getTimeOfDayStringAsMinutes = timeOfDayString => {
  if (!timeOfDayString) {
    return undefined
  }
  const [h, m] = timeOfDayString.split(":").map( val => parseInt(val) );
  return (60 * h) + m
}

export const getMinutesAsTimeOfDayString = minutes => dayjs.duration({hours: Math.floor(minutes / 60), minutes: minutes % 60}).format("HH:mm")

export const isTodayUnix = timestampSeconds => {
  const date = dayjs().local()
  const inputDate = dayjs.unix(timestampSeconds).local()
  return (date.date() === inputDate.date()) && (date.month() === inputDate.month()) && (date.year() === inputDate.year())
} 

export const convertToDate = timestampSeconds => {
  return dayjs.unix(timestampSeconds).local().format("MMM D")
}

export const convertToArticleDate = timestampSeconds => {
  return dayjs.unix(timestampSeconds).local().format("MMM D, YYYY")
}

export const formatDurationSeconds = durationSeconds => {
  return dayjs.duration({seconds: durationSeconds}).format("m:ss")
}

export const formatReadableUnixTimestamp = timestampSeconds => {
  return dayjs.unix(timestampSeconds).format("dddd, MMMM D, YYYY")
}

export const formatTestimonialUnixTimestamp = timestampSeconds => {
  return dayjs.unix(timestampSeconds).format("MMM D, YYYY")
}


export const formatReadableUnixTimestampMins = timestampSeconds => {
  return dayjs.unix(timestampSeconds).format("MMM D, h:mm A").toUpperCase()
}

export const getNextEnrollmentCloseUnixTimestamp = () => {
  const secondsInWeek = 86400 * 7
  const baseEnrollmentCloseUnixTimestamp = 1685606400
  const currentTimestampUnix = dayjs().unix()
  let outputTimestamp = baseEnrollmentCloseUnixTimestamp
  // while (outputTimestamp < currentTimestampUnix) {
  //   outputTimestamp += secondsInWeek
  // }
  const is_closed = outputTimestamp < currentTimestampUnix
  return {date: dayjs.unix(outputTimestamp).toDate(), is_closed}
}


export const getDiscountCodeExpirationMetadata = (expirationTimestamp) => {
  const currentTimestampUnix = dayjs().unix()

  const is_expired = expirationTimestamp < currentTimestampUnix
  const days_since_expiration = dayjs.unix(currentTimestampUnix).diff(dayjs.unix(expirationTimestamp), 'day', true/*floatResult*/)
  const days_until_expiration = dayjs.unix(expirationTimestamp).diff(dayjs.unix(currentTimestampUnix), 'day', true/*floatResult*/)
  return {
    expiration_date: dayjs.unix(expirationTimestamp).toDate(),
    app_expiration_date: dayjs.unix(expirationTimestamp).add(-1, 'day').toDate(),
    is_expired,
    days_since_expiration,
    days_until_expiration
  }
}


export const hoursSinceUnixTimestamp = (timestampSeconds, floatResult=false) => {
  return currentTime().diff(dayjs.unix(timestampSeconds), 'hour', floatResult)
}

export const getFormattedDateDaysFromNow = (n) => {
  return dayjs().add(n, 'day').format("MMMM D, YYYY")
}

export const getDateStringDaysFromNow = (date, n) => {
  return date.add(n, 'day').format("MM-DD-YYYY")
}

export const getCourseExpirationTime = () => {
  const currTime = currentTime()
  const endOfHour = currTime.second(0).millisecond(0).minute(60)
  return endOfHour.diff(currTime, 'minute') > 10 ? endOfHour : endOfHour.add(1, 'hour')
}

export const convertUnixTimestamp = unixTimestamp => dayjs.unix(unixTimestamp)

export const getCurrentTimePlusHours = hours => currentTime().add(hours, 'hour')
export const getCurrentTimePlusDays = day => currentTime().add(day, 'day')

export const getTimestampPlusHours = (unixTimestamp, hours) => dayjs.unix(unixTimestamp).add(hours, 'hour')

export const getTimestampPlusSeconds = (time, seconds) => time.add(seconds, 'second')

export const getLocalDaysSinceTimestamp = (timestampSeconds) => {
  const currentDate = currentTime().local().set('hour', 0).set('minute', 0).set('second', 0)
  const timestampDate = dayjs.unix(timestampSeconds).local().set('hour', 0).set('minute', 0).set('second', 0)

  return Math.floor(currentDate.diff(timestampDate, 'day'))
}

export const getLocalWeeksSinceTimestamp = (timestampSeconds) => {
  const currentDate = currentTime().local().set('hour', 0).set('minute', 0).set('second', 0)
  const timestampDate = dayjs.unix(timestampSeconds).local().set('hour', 0).set('minute', 0).set('second', 0)

  return Math.floor(currentDate.diff(timestampDate, 'week'))
}

export const getSecondsSinceTimestamp = (timestampSeconds, decimalResult=false) => {
  return currentTime().diff(dayjs.unix(timestampSeconds), 'second', decimalResult)
}

export const getMinutesSinceTimestamp = (timestampSeconds, decimalResult=false) => {
  return currentTime().diff(dayjs.unix(timestampSeconds), 'minute', decimalResult)
}

export const getHoursSinceTimestamp = (timestampSeconds, decimalResult=false) => {
  return currentTime().diff(dayjs.unix(timestampSeconds), 'hour', decimalResult)
}

export const getDaysSinceTimestamp = (timestampSeconds, decimalResult=false) => {
  return currentTime().diff(dayjs.unix(timestampSeconds), 'day', decimalResult)
}

export const getWeeksSinceTimestamp = (timestampSeconds, decimalResult=false) => {
  return currentTime().diff(dayjs.unix(timestampSeconds), 'week', decimalResult)
}


export const getMonthsSinceTimestamp = (timestampSeconds, decimalResult=false) => {
  return currentTime().diff(dayjs.unix(timestampSeconds), 'month', decimalResult)
}

export const getYearsSinceTimestamp = (timestampSeconds, decimalResult=false) => {
  return currentTime().diff(dayjs.unix(timestampSeconds), 'year', decimalResult)
}

export const getAbbreviatedDurationSinceTimestamp = (timestampSeconds) => {
  if (getYearsSinceTimestamp(timestampSeconds) >= 1) {
    return `${Math.floor(getYearsSinceTimestamp(timestampSeconds))}Y`
  } else if (getMonthsSinceTimestamp(timestampSeconds) >= 1) {
    return `${Math.floor(getMonthsSinceTimestamp(timestampSeconds))}M`
  } else if (getWeeksSinceTimestamp(timestampSeconds) >= 1) {
    return `${Math.floor(getWeeksSinceTimestamp(timestampSeconds))}W`
  } else if (getDaysSinceTimestamp(timestampSeconds) >= 1) {
    return `${Math.floor(getDaysSinceTimestamp(timestampSeconds))}D`
  } else if (getHoursSinceTimestamp(timestampSeconds) >= 1) {
    return `${Math.floor(getHoursSinceTimestamp(timestampSeconds))}H`
  } else if (getMinutesSinceTimestamp(timestampSeconds) >= 1) {
    return `${Math.floor(getMinutesSinceTimestamp(timestampSeconds))} MIN.`
  } else {
    return `${Math.floor(getSecondsSinceTimestamp(timestampSeconds))}S`
  }
}

export const getReadableDurationSinceTimestamp = (timestampSeconds) => {
  return dayjs().to(dayjs.unix(timestampSeconds))
}

export const getCurrentYear = () => {
  return currentTime().format("YYYY")
}

export const getAdvertisedYear = () => {
  return currentTime().add(4, 'month').format("YYYY")
}

export const getIntSecondsSinceTime = (time) => {
  return Math.floor(currentTime().diff(time, 'second'))
}

export const shouldAutoRefreshApp = (appLoadedAt) => {
  const currTime = currentTime()
  const format = 'DD/MM/YYYY'
  return (currTime.diff(appLoadedAt, 'hour', true) >= 1.0) || (currTime.format(format) !== appLoadedAt.format(format))
}

/////////////
// Cookies //
/////////////
const ACCOUNT_CREATION_NONCE = 'account_creation_nonce'
const CSRF_TOKEN_KEY = 'csrf_token'
const DASHBOARD_CSRF_TOKEN_KEY = 'dashboard_csrf_token'
export const INVITE_CODE_LOCAL_STORAGE_KEY = "alma__local_storage_b2b_invite_code"
const COOKIE_MISSING = "_missing"
const FB_PIXEL_FBC_COOKIE = "_fbc"
const FB_PIXEL_FBP_COOKIE = "_fbp"
const TIKTOK_PIXEL_TTP_COOKIE = "_ttp"
const TIKTOK_PIXEL_TTCLID_COOKIE = "ttclid"
export const getCookie = (name) => {
  const nameEQ = name + "=";
  const ca = document.cookie.split(';');
  for(let i=0;i < ca.length;i++) {
    let c = ca[i];
    while (c.charAt(0)===' ') c = c.substring(1,c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
  }
  return COOKIE_MISSING;
}

export const setCookie = (name, value, days) => {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}

export const getCsrfTokenFromCookies = () => getCookie(CSRF_TOKEN_KEY)
export const getCsrfTokenCookieIsPresent = () => {
  const c = getCookie(CSRF_TOKEN_KEY)
  return (c !== COOKIE_MISSING) && !!(c)
}

export const getDashboardCsrfTokenFromCookies = () => getCookie(DASHBOARD_CSRF_TOKEN_KEY)
export const getAppleNonceTokenFromCookies = () => getCookie(ACCOUNT_CREATION_NONCE)

export const getPixelParams = () => {
  const fbc_cookie = getCookie(FB_PIXEL_FBC_COOKIE)
  const fbc = (fbc_cookie !== "_missing") ? (fbc_cookie || "") : ""
  const fbp_cookie = getCookie(FB_PIXEL_FBP_COOKIE)
  const fbp = (fbp_cookie !== "_missing") ? (fbp_cookie || "") : ""
  const fbclid = getFbclidFromQueryString() || ""
  const ttp_cookie = getCookie(TIKTOK_PIXEL_TTP_COOKIE)
  const ttp  = (ttp_cookie !== "_missing") ? (ttp_cookie || "") : ""
  const ttclid_cookie = getCookie(TIKTOK_PIXEL_TTCLID_COOKIE)
  const ttclid = getTTClidFromQueryString() || ((ttclid_cookie !== "_missing") ? (ttclid_cookie || "") : "") || ""
  return {fbc, fbp, fbclid, ttp, ttclid}
}

export const delay = (ms) => new Promise(res => setTimeout(res, ms))

export const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
}

export const resizeInnerHeight = () => {
   const innerHeight = document.body.clientHeight || window.innerHeight;
   document.body.height = innerHeight
   return innerHeight
}

export const resizeInnerWidth = () => {
  const innerWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
  document.body.width = innerWidth
  return innerWidth
}

export const asyncCallWithTimeout = async (asyncPromise, timeLimit) => {
    let timeoutHandle;

    const timeoutPromise = new Promise((_resolve, reject) => {
        timeoutHandle = setTimeout(
            () => reject(new Error('Async call timeout limit reached')),
            timeLimit
        );
    });

    return Promise.race([asyncPromise, timeoutPromise]).then(result => {
        clearTimeout(timeoutHandle);
        return result;
    })
}

export const loadScriptAsync = (scriptSrc) => {
  const script = document.createElement("script");
  script.src = scriptSrc
  script.async = true;
  document.body.appendChild(script);
}

export const MouseFlowScript = "/assets/html/certification-mf.js"
export const CertificationVslScriptSrc = "/assets/html/certification-vsl-video.js"
export const CertificationPageVideoScriptSrc = "/assets/html/certification-vsl-video.js"
export const LoloVslScriptSrc = "/assets/html/lolo-vsl-video.js"



let SharedSentryInstance;

const getSentry = async () => {
  while (!SharedSentryInstance) {
    await delay(1000)
  }
  return SharedSentryInstance
}

export const initializeSentry = async () => {
  try {
    if (isProduction && !SharedSentryInstance) {
      const Sentry = await import('@sentry/browser')
      Sentry.init({
        dsn: "https://5b76037f5e044d60a955793ebc07c57d@o1371970.ingest.sentry.io/6676605",
        environment: "production",
        tracesSampleRate: 1.0,
      });
      SharedSentryInstance = Sentry
    }
  } catch (err) {
    console.error(err)
  }
}

export const captureSentryException = async (error) => {
  const sentryInstance = await getSentry()
  sentryInstance.captureException(error)
}

export const DefaultAspectRatio = 0.5625

export const MinTabletWidthPixels = 768
export const MinDesktopWidthPixels = 961
export const MinLargeDesktopWidthPixels = 1500

export const MaxUsernameCharacters = 30

export const DevicePixelRatio = (function () {
    var ctx = document.createElement("canvas").getContext("2d"),
        dpr = window.devicePixelRatio || 1,
        bsr = ctx.webkitBackingStorePixelRatio ||
              ctx.mozBackingStorePixelRatio ||
              ctx.msBackingStorePixelRatio ||
              ctx.oBackingStorePixelRatio ||
              ctx.backingStorePixelRatio || 1;

    return dpr / bsr;
})();


export const sanitizeEmailAddress = emailAddress => emailAddress.toLowerCase().replace(/\s+/g, '')

export const sanitizeAffiliateCode = affiliateCode => affiliateCode.toUpperCase().replace(/\s+/g, '')

export const sanitizeCheckoutCode = affiliateCode => affiliateCode.toUpperCase().replace(/[^0-9A-Za-z\-]/g, '')

export const sanitizeUsername = affiliateCode => affiliateCode.toLowerCase().replace(/[^0-9a-z\.\_]/g, '').slice(0, MaxUsernameCharacters)

export const sanitizeTrainerInviteCode = trainerInviteCode => trainerInviteCode.toUpperCase().replace(/\s+/g, '')

export const sanitizeUsdCentsString = inputUsdDollars => parseInt(inputUsdDollars.replaceAll(/[^0-9]/g, '')) * 100

export const sanitizeNumber = inputNumberString => parseInt(inputNumberString.replaceAll(/[^0-9]/g, ''))

export const sanitizeWebsiteAddress = websiteAddress => websiteAddress.toLowerCase().replace(/\s+/g, '')

export const validateUrl = urlString => {
  try {
    new URL(urlString);
    return true;
  } catch (err) {
    return false;
  }
}

export const preloadImagePaths = imagePaths => {
  imagePaths.map(imageSrc => {
    const img = new Image();
    img.src = imageSrc
  })
}

export const TRAINER_CERTIFICATION_MOBILE_HERO_IMAGE_PATH = "/images/certification-assets/hero-mobile.webp?v=1"
export const TRAINER_CERTIFICATION_DESKTOP_HERO_IMAGE_PATH = "/images/certification-assets/hero-desktop.webp?v=1"

export const MASTER_COACH_MOBILE_HERO_IMAGE_PATH = "/images/master-coach/mc-hero-mobile.webp?v=2"
export const MASTER_COACH_DESKTOP_HERO_IMAGE_PATH = "/images/master-coach/mc-hero-desktop.webp?v=2"

export const onceEventListener = (el, event, fn, opts) => {
  var onceFn = function (e) {
    el.removeEventListener(event, onceFn);
    fn.apply(this, arguments);
  };
  el.addEventListener(event, onceFn, opts);
  return onceFn;
}

export const NeuroFitQuarterlyProductId = "neurofit_membership_quarterly"
export const NeuroFitYearlyProductId = "neurofit_membership_yearly"
export const NeuroFitQuarterlyProductIdNoTrial = "neurofit_membership_quarterly_no_trial"
export const NeuroFitYearlyProductIdNoTrial = "neurofit_membership_yearly_no_trial"

export const NeuroFitQuarterlyProductIdV2 = "neurofit_membership_quarterly_v2"
export const NeuroFitYearlyProductIdV2 = "neurofit_membership_yearly_v2"
export const NeuroFitQuarterlyProductIdNoTrialV2 = "neurofit_membership_quarterly_notrial_v2"
export const NeuroFitYearlyProductIdNoTrialV2 = "neurofit_membership_yearly_no_trial_v2"

export const FreeTrialInAppPurchaseProducts = [
  {
    id: NeuroFitQuarterlyProductId,
    title: "3 Months",
    description: "Quarterly NeuroFit Membership",
    price: "$39.99",
    priceMicros: 39990000,
    currency: "USD"
  },
  {
    id: NeuroFitYearlyProductId,
    title: "Yearly",
    description: "Yearly NeuroFit Membership",
    price: "$124.99",
    priceMicros: 124990000,
    currency: "USD"
  },
  {
    id: NeuroFitQuarterlyProductIdV2,
    title: "3 Months",
    description: "Quarterly NeuroFit Membership",
    price: "$59.99",
    priceMicros: 59990000,
    currency: "USD"
  },
  {
    id: NeuroFitYearlyProductIdV2,
    title: "Yearly",
    description: "Yearly NeuroFit Membership",
    price: "$179.99",
    priceMicros: 179990000,
    currency: "USD"
  }
]

export const NoFreeTrialInAppPurchaseProducts = [
  {
    id: NeuroFitQuarterlyProductIdNoTrial,
    title: "Quarterly",
    description: "Quarterly NeuroFit Membership (No Free Trial)",
    price: "$39.99",
    priceMicros: 39990000,
    currency: "USD"
  },
  {
    id: NeuroFitYearlyProductIdNoTrial,
    title: "Yearly",
    description: "Yearly NeuroFit Membership (No Free Trial)",
    price: "$124.99",
    priceMicros: 124990000,
    currency: "USD"
  },
  {
    id: NeuroFitQuarterlyProductIdNoTrialV2,
    title: "Quarterly",
    description: "Quarterly NeuroFit Membership (No Free Trial)",
    price: "$59.99",
    priceMicros: 59990000,
    currency: "USD"
  },
  {
    id: NeuroFitYearlyProductIdNoTrialV2,
    title: "Yearly",
    description: "Yearly NeuroFit Membership (No Free Trial)",
    price: "$179.99",
    priceMicros: 179990000,
    currency: "USD"
  }
]

export const REFRESH_STATISTICS_COUNTDOWN_SECONDS = 7
